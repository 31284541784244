import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Wrapper = styled.div`
  margin: 15rem auto;
  padding: 0 1rem;
  max-width: 75rem;
`

const Title = styled.h1`
  font-size: 3rem;
`

const Message = styled.p`
  font-size: 1.5rem;
`

const HomeButton = styled.button`
  display: inline-block;
  margin: 2rem 0 1rem 0;
  padding: 1rem;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #025458;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: 0.15s ease-out;

  &:hover {
    -webkit-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.05);
    transition: 0.1s ease-in;
  }

  &:active {
    outline: none;
    transform: scale(0.9);
  }
`

export default function NotFound() {
  return (
    <Layout>
      <Seo
        title="Page Not Found"
        description="The page you requested cannot be found. You may have followed an outdated link, or have mistyped a URL."
      />
      <Wrapper>
        <Title>Page not found</Title>
        <Message>
          Sorry! The page you're looking for may have been moved or deleted.{" "}
        </Message>
        <Link to="/">
          <HomeButton>Go back home</HomeButton>
        </Link>
      </Wrapper>
    </Layout>
  )
}
